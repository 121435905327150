import React, { useState, useEffect } from 'react'
import { navigate } from "gatsby"
import { useLocation } from '@reach/router'
import Layout from '../components/Layout'
import Results from '../components/Results'
import Header from '../components/Header'
import Seo from '../components/Seo'


const URLPATTERN = /https?:\/\/arxiv\.org\/(?:abs|pdf)\/((?:[\w.-]+\/[\d]+)|([\d]+\.[\d]+))/

const fetchResults = (q, isUrl) => {
  const url = (isUrl ?
    "https://us-west1-semanticxplorer.cloudfunctions.net/semantic-xplorer-db-similarity" :
    "https://us-west1-semanticxplorer.cloudfunctions.net/semantic-xplorer-db")
  return fetch(`${url}?${q}`)
    .then(async (response) => {
      if (response.status !== 200) {
        throw new Error(await response.text())
      } else {
        return response.json()
      }
    })
}

const makeQueryString = (query, categories, years, page) => {
  const queryObj = { query }
  if (categories.length > 0) queryObj["c"] = categories
  if (years.length > 0) queryObj["y"] = years
  if (page && page > 0) queryObj["p"] = page
  return new URLSearchParams(queryObj).toString()
}

const Index = () => {
  const [results, setResults] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [pageLoading, setPageLoading] = useState(false)
  const [error, setError] = useState("")
  const [resultsOrder, setResultsOrder] = useState("score")
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const urlQuery = urlParams.get("query")
  const [query, setQuery] = useState(urlQuery)
  const catQuery = urlParams.get("c")
  const [categories, setCategories] = useState(catQuery?.split(",") || [])
  const yearQuery = urlParams.get("y")
  const [years, setYears] = useState(yearQuery?.split(",") || [])

  // useEffect(()=>{
  //   if (query !== urlQuery) setQuery(urlQuery)
  // }, [location])

  const searchByPaper = (paper_id) => {
    setQuery(`https://arxiv.org/abs/${paper_id}`)
  }

  const addCategory = (value) => {
    const newCats = [...categories];
    const valueIndex = newCats.indexOf(value);
    if (valueIndex === -1) {
      newCats.push(value);
    } else {
      newCats.splice(valueIndex, 1);
    }
    setCategories(newCats);
  }

  useEffect(() => {
    if (page > 0){
      setPageLoading(true)
      const q = makeQueryString(query, categories, years, page)
      const isUrl = query.match(URLPATTERN)

      fetchResults(q, isUrl).then((newPage) => {
        if (newPage) {
          if (resultsOrder === "date") {
            newPage.sort((a,b) => new Date(b.metadata.date) - new Date(a.metadata.date))
          }
          setResults([...results, ...newPage])
          if (newPage.length === 0) setError("No more results!")
          if (newPage.length < 12) setPage(10)
        }
      })
      .catch((e) => {
        console.error(e)
        setError(e.message.toString())
      })
      .finally(() => setPageLoading(false))
    }
  }, [page])

  useEffect(() => {
    if (query) {
      setPage(0)
      setLoading(true)
      setError("")

      const q = makeQueryString(query, categories, years)
      if (query !== urlQuery || categories !== catQuery || years !== yearQuery) navigate(`/?${q}`)

      const isUrl = query.match(URLPATTERN)
      if (query.startsWith("http") && !isUrl) {
        setError("It looks like you're searching for a paper, but your arXiv URL is invalid.")
      }

      fetchResults(q, isUrl).then((results) => {
        if (results) {
          if (resultsOrder === "date") {
            results.sort((a,b) => new Date(b.metadata.date) - new Date(a.metadata.date))
          }
          setResults(results)

          if (results.length === 0) setError("No results!")
        }
      })
      .catch((e) => {
        console.error(e)
        setError(e.message.toString())
      })
      .finally(() => setLoading(false))
    }
  }, [query, categories, years]) // Dependency is missing urlQuery and resultsOrder, but I don't want the fetch to be remade!!

  useEffect(() => {
    if (resultsOrder === "date") {
      setResults(r=>[...r].sort((a,b) => new Date(b.metadata.date) - new Date(a.metadata.date)))
    } else {
      setResults(r=>[...r].sort((a,b) => b.score - a.score))
    }
  }, [resultsOrder])

  const submitQuery = (query) => {
    setQuery(query)
  }

  return (
    <Layout>
      <Header onSubmit={submitQuery} isLoading={isLoading} error={error} urlQuery={urlQuery} setCategories={setCategories} setYears={setYears} categories={categories} years={years} />
      <Results results={results} searchByPaper={searchByPaper} changeOrder={setResultsOrder} isLoading={isLoading} categories={categories} addCategory={addCategory} incrementPage={()=>setPage(page+1)} pageLoading={pageLoading} showMore={page < 8} />
    </Layout>
  )
}

export default Index

export const Head = () => (
  <Seo />
)
