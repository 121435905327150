import React, { useState, useEffect } from 'react'
import { useColorModeValue, Collapse, FormErrorMessage, InputLeftElement, HStack, InputGroup, Flex, Box, Input, FormControl, Button } from '@chakra-ui/react'
import { SearchIcon } from "@chakra-ui/icons"
import { ChevronUpIcon, ChevronDownIcon } from "@chakra-ui/icons"

import Filters from './Filters'

const Search = ({ urlQuery, onQueryChange, isLoading, error, categories, years, setCategories, setYears }) => {
  const [query, setQuery] = useState(urlQuery || "")
  const [showFilters, setShowFilters] = useState(false)
  const isError = error !== ''
  const colorMode = useColorModeValue("gray.700", "gray.200")

  const handleChange = (event) => {
    event.preventDefault()
    setQuery(event.target.value)
  }

  const submit = () => {
    onQueryChange(query)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      submit()
    }
  }

  useEffect(()=>{
    if (urlQuery && query !== urlQuery) {
      setQuery(urlQuery)
    }
  }, [urlQuery])

  return (
    <Flex justifyContent="center" mt={8} mb={2} p={2}>
      <Box maxW="500px" w="full">
        <FormControl isInvalid={isError}>
          <InputGroup>
            <InputLeftElement
              pointerEvents='none'
              color='gray.300'
              fontSize='1.2em'
            >
              <SearchIcon />
            </InputLeftElement>
            <Input
              type="search"
              placeholder="Enter a query"
              value={query}
              focusBorderColor="gray.200"
              _hover={{ boxShadow: 'none', borderColor: "gray.200" }}
              _placeholder={{ color: colorMode, opacity: 0.8 }}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              autoFocus
            />
          </InputGroup>
          {isError && (
            <FormErrorMessage>{error}</FormErrorMessage>
          )}
        </FormControl>
        <Collapse in={showFilters} style={{overflow: "visible", zIndex: 5, position: "relative"}}>
          <Filters setCategories={setCategories} setYears={setYears} initCats={categories} initYears={years} />
        </Collapse>
        <Flex justify="center" pt={4}>
          <HStack>
            <Button type="submit" onClick={submit} isLoading={isLoading} loadingText='Search'>Search</Button>
            <Button onClick={()=>setShowFilters(!showFilters)} rightIcon={showFilters ? <ChevronUpIcon/> : <ChevronDownIcon/>}>Filters</Button>
          </HStack>
        </Flex>
      </Box>
    </Flex>
  )
}

export default Search
