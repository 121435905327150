import React from 'react'
import { Box, Flex, Heading } from '@chakra-ui/react'

import Search from './Search'

const Header = ({ onSubmit, isLoading, urlQuery, error, setCategories, setYears, categories, years }) => {
  return (
    <Flex justifyContent="center" mt={8}>
      <Box maxW="4xl" w="full" justifyContent="center">
        <Heading as="h1" size={{ base: 'xl', md: '3xl' }} textAlign="center" mb={2}>arXiv Xplorer</Heading>
        <Heading as="h4" size="sm" textAlign="center">The Semantic Search Engine for ArXiv.</Heading>
        <Search urlQuery={urlQuery} onQueryChange={onSubmit} isLoading={isLoading} error={error} setCategories={setCategories} setYears={setYears} categories={categories} years={years} />
      </Box>
    </Flex>
  )
}

export default Header
