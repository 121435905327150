import React, { useEffect, useState } from "react";
import { FormControl, Flex, Box, Tooltip, useColorModeValue } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { CATEGORIES, YEARS } from "../data/categories"

const tag_options = Object.entries(CATEGORIES).map(([code, desc]) => ({ value: code, label: code, desc: desc }))
const year_options = YEARS.map(y => {
  return { label: String(y), value: y }
})
const TagLabel = (props) => {
  return (
    <Tooltip label={props.data.desc}>{props.data.label}</Tooltip>
  );
};

const Filters = ({ setCategories, setYears, initCats, initYears }) => {
  const [cats, setLocalCats] = useState(initCats)
  const [years, setLocalYears] = useState(initYears.map(x => Number(x)))
  const [update, setUpdate] = useState(false)
  const colorMode = useColorModeValue("gray.700", "gray.200")
  const styles = {
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        opacity: 0.8,
        color: colorMode,
      }
    }
  }

  useEffect(() => {
    if (cats !== initCats) {
      setLocalCats(initCats)
    }
  }, [initCats])

  useEffect(() => {
    if (update) {
      setCategories(cats)
      setUpdate(false)
    }
  }, [update, cats])

  useEffect(() => {
    if (update) {
      setYears(years)
      setUpdate(false)
    }
  }, [update, years])

  return (
    <FormControl pt={4}>
      <Flex>
        <Box flex="1" p={1} position="relative">
          <Select
            useBasicStyles
            isMulti
            value={tag_options.filter(x => cats.includes(x.value))}
            name="tags"
            options={tag_options}
            placeholder="Tags"
            chakraStyles={styles}
            closeMenuOnSelect={false}
            selectedOptionStyle="check"
            hideSelectedOptions={false}
            isClearable={false}
            onChange={v => setLocalCats(v.map(x => x.value))}
            onMenuClose={() => setUpdate(true)}
            getOptionLabel={(option) => `${option.label} - ${option.desc}`}
            size="sm"
            components={{ MultiValueLabel: TagLabel }}
          />
        </Box>
        <Box flex="1" p={1}>
          <Select
            useBasicStyles
            isMulti
            _placeholder={{ color: colorMode }}
            chakraStyles={styles}
            value={year_options.filter(x => years.includes(x.value))}
            isClearable={false}
            closeMenuOnSelect={false}
            name="year"
            options={year_options}
            placeholder="Year"
            onChange={v => setLocalYears(v.map(x => x.value))}
            onMenuClose={() => setUpdate(true)}
            selectedOptionStyle="check"
            hideSelectedOptions={false}
            size="sm"
          />
        </Box>
      </Flex>
    </FormControl>
  )
}

export default Filters;
