import React, { useState, useEffect, useRef } from 'react'
import { useColorModeValue, Skeleton, SkeletonText, Select, Wrap, Tag, TagLabel, CardFooter, Divider, Tooltip, Box, Button, Link, Flex, Card, CardHeader, SimpleGrid, CardBody, Heading, Text, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, ModalFooter, HStack } from '@chakra-ui/react'
import { ExternalLinkIcon, TriangleUpIcon } from "@chakra-ui/icons"
import { CATEGORIES } from "../data/categories"
import { navigate } from 'gatsby'
import { useSettings } from "./SettingsProvider";

const Score = ({ score }) => {
  return (
    <Tooltip label="Embedding Score" placement="top">
      <Box justify="center" align="center" w={6} mt={-1}>
        <TriangleUpIcon boxSize={4} />
        <Text fontWeight="bold" fontSize="xs">{Math.round(score * 100)}</Text>
      </Box>
    </Tooltip>
  )
}

const CategoryTags = ({ id, categories, selectedCategories, onClick }) => {
  return (<Wrap spacing={2} shouldWrapChildren={true} maxH="12">
    {categories.filter(c => CATEGORIES.hasOwnProperty(c)).map((c, i) => (
      <Tooltip label={CATEGORIES[c]} key={`${id}-cat-${i}`}>
        <Tag
          cursor="pointer"
          size="sm"
          variant={selectedCategories.includes(c) ? 'solid' : 'outline'}
          onClick={e=>{e.stopPropagation(); onClick(c)}
        }>
          <TagLabel>{c}</TagLabel>
        </Tag>
      </Tooltip>
    ))}
  </Wrap>)
}

const simpleDate = date => {
  const d = new Date(date)
  const m = d.getMonth()
  const y = d.getFullYear()
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  return `${monthNames[m]}. ${y}`;
}

const Results = ({ results, searchByPaper, changeOrder, isLoading, categories, addCategory, incrementPage, pageLoading, showMore }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedResult, setSelectedResult] = useState({metadata: {}})
  const colorMode = useColorModeValue("gray.600", "gray.300")
  const { defaultUrl } = useSettings()
  const currentIndex = useRef()

  const handleResultClick = (result) => {
    setSelectedResult(result)
    currentIndex.current = results.indexOf(result);
    setIsModalOpen(true)
    window.history.pushState({}, "")
  }

  const closeModal = () => {
    navigate(-1)
    setIsModalOpen(false)
  }

  const moreLikeThis = (id) => {
    closeModal()
    searchByPaper(id)
  }

  useEffect(() => {
    const closeModalBackButton = e => {
      if (isModalOpen){
        setIsModalOpen(false)
      }
    }
    window.addEventListener("popstate", closeModalBackButton)
    return () => window.removeEventListener("popstate", closeModalBackButton)
  }, [isModalOpen])

  useEffect(() => {
    const handleKeyDown = (event) => {
      let newIndex;
      if (event.key === 'ArrowRight') {
        newIndex = currentIndex.current + 1;
        if (newIndex < results.length) {
          currentIndex.current = newIndex;
          setSelectedResult(results[newIndex]);
        }
      }
      else if (event.key === 'ArrowLeft') {
        newIndex = currentIndex.current - 1;
        if (newIndex >= 0) {
          currentIndex.current = newIndex;
          setSelectedResult(results[newIndex]);
        }
      }
    };

    if (isModalOpen) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isModalOpen, results]);

  return (
    <Flex justifyContent="center">
      <Box maxW="6xl" w="100%" mb={{ base: 32, md: 16 }}>
        {results.length > 0 &&
          <Flex justifyContent="flex-end" pr={{ base: 1.5, lg: 2 }}>
            <Select onChange={e=>changeOrder(e.target.value)} defaultValue="score" size="sm" maxW="36">
              <option value='score'>Sort by Score</option>
              <option value='date'>Sort by Date</option>
            </Select>
          </Flex>}
        <SimpleGrid templateColumns="repeat(auto-fill, minmax(280px, 1fr))" gap={{ base: 3, lg: 4 }} w="100%">
          {results.map((result) => (
            <Card m={2} key={result.id} onClick={() => !isLoading && handleResultClick(result)} cursor="pointer" _hover={{ boxShadow: 'xl' }} transition="200ms" boxShadow={{ base: "lg", lg: "md"}}>
              <CardHeader pb={0}>
                <Skeleton isLoaded={!isLoading}>
                  <Flex justifyContent="space-between">
                    <Heading size='md' flex={1}>{result.metadata.title}</Heading>
                    <Score score={result.score} />
                  </Flex>
                </Skeleton>
                <Flex justifyContent="space-between">
                  <Skeleton isLoaded={!isLoading} mt={1}>
                    <Text pt={0} pb={0} color={colorMode} fontSize="sm">{result.metadata.short_author}</Text>
                  </Skeleton>
                  <Skeleton isLoaded={!isLoading} mt={1}>
                    <Text pt={0} pb={0} color={colorMode} fontSize="sm" pl={2}>{simpleDate(result.metadata.date)}</Text>
                  </Skeleton>
                </Flex>
              </CardHeader>
              <CardBody pb={3}>
                <SkeletonText noOfLines={4} spacing='3' skeletonHeight='3' isLoaded={!isLoading}>
                  <Text noOfLines={[4, 4, 5]}>{result.metadata.abstract}</Text>
                </SkeletonText>
              </CardBody>
              <CardFooter pt={0}>
                <Skeleton isLoaded={!isLoading} w="full">
                  <Flex justifyContent="space-between" alignItems="center">
                    <CategoryTags id={result.id} categories={result.metadata.categories} selectedCategories={categories} onClick={v=>{closeModal(); addCategory(v)}} />
                    <Box pl={4}>
                      <Link href={`${defaultUrl.value}${result.id}`} isExternal>
                        <Button variant="solid" size="xs" rightIcon={<ExternalLinkIcon />} onClick={e=>e.stopPropagation()}>View</Button>
                      </Link>
                    </Box>
                  </Flex>
                </Skeleton>
              </CardFooter>
            </Card>
          ))}
        </SimpleGrid>
        {results.length > 0 && showMore &&
        <Flex justifyContent="center" p={4}>
          <Button size="lg" onClick={incrementPage} isLoading={pageLoading}>Show More</Button>
        </Flex>}
      </Box>
      <Modal mt={4} isOpen={isModalOpen} onClose={closeModal} size={{ base: "full", sm: "xl" }} scrollBehavior="inside" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text>{selectedResult.metadata.title}</Text>
            <Flex justifyContent="space-between" alignItems="flex-start">
              <Text fontWeight="bold" fontSize="sm" color={colorMode} flex="1" noOfLines={2}>{selectedResult.metadata.authors}</Text>
              <Text fontSize="sm" color={colorMode} flex="0 0 auto" pl={3}>{simpleDate(selectedResult.metadata.date)}</Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{selectedResult.metadata.abstract}</Text>
          </ModalBody>
          <Divider />
          <ModalFooter>
            <Flex justifyContent="space-between" flex="1" alignItems="center">
              <CategoryTags id={selectedResult.id} categories={selectedResult.metadata.categories} selectedCategories={categories} onClick={v=>{closeModal(); addCategory(v)}} />
              <HStack spacing={2} pl={2}>
                <Button variant="outline" onClick={()=>moreLikeThis(selectedResult.id)}>More Like This</Button>
                <Link href={`${defaultUrl.value}${selectedResult.id}`} isExternal>
                  <Button rightIcon={<ExternalLinkIcon />}>View</Button>
                </Link>
              </HStack>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  )
}

export default Results
